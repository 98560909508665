import { Injectable } from '@angular/core';
import * as moment from 'moment';
export const yearlyTimeFrames: string[] = ['qtr1', 'qtr2', 'qtr3', 'qtr4', 'summer'];

@Injectable({
	providedIn: 'root',
})
export class TimeFrameService {
	currentYear = moment().startOf('year');
	refinedYear = moment().format('YYYY');
	todayStart = moment().startOf('day');
	todayEnd = moment().endOf('day');
	weekStart = moment().startOf('week');
	weekEnd = moment().endOf('week');
	lastWeekStart = moment().startOf('week').subtract(1, 'week');
	lastWeekEnd = moment().endOf('week');
	monthStart = moment().startOf('month');
	monthEnd = moment().endOf('month');
	lastMonthStart = moment().startOf('month').subtract(1, 'month');
	lastMonthEnd = moment().endOf('month').subtract(1, 'month');
	yearStart = moment().startOf('y');
	yearEnd = moment().endOf('y');
	lastYearStart = moment().startOf('y').subtract(1, 'year');
	lastYearEnd = moment().endOf('y').subtract(1, 'year');
	summerStart = moment(this.refinedYear + '-06-01');
	summerEnd = moment(this.refinedYear + '-08-31');
	qtr1Start = moment(this.refinedYear, 'YYYY').quarter(1).startOf('Q');
	qtr1End = moment(this.refinedYear, 'YYYY').quarter(1).endOf('Q');
	qtr2Start = moment(this.refinedYear, 'YYYY').quarter(2).startOf('Q');
	qtr2End = moment(this.refinedYear, 'YYYY').quarter(2).endOf('Q');
	qtr3Start = moment(this.refinedYear, 'YYYY').quarter(3).startOf('Q');
	qtr3End = moment(this.refinedYear, 'YYYY').quarter(3).endOf('Q');
	qtr4Start = moment(this.refinedYear, 'YYYY').quarter(4).startOf('Q');
	qtr4End = moment(this.refinedYear, 'YYYY').quarter(4).endOf('Q');

	allStart = moment('2000-08-31');
	allEnd = moment('2050-01-01');

	getTimeFrame(timeframe, year, asMoment) {
		let yearStartDate = asMoment ? this.allStart : this.allStart['_d'];
		let yearEndDate = asMoment ? this.allEnd : this.allEnd['_d'];
		const ytdEndDate = moment(`${year}-${moment().format('MM')}-${moment().format('DD')}`);

		if (year !== 'all') {
			this.updateYear(year);
			const startYear = moment(`${year}-01-01`);
			const endYear = moment(`${year}-12-31`);
			yearStartDate = asMoment ? startYear : startYear['_d'];
			yearEndDate = asMoment ? endYear : endYear['_d'];
		} else {
			this.updateYear(moment().format('YYYY'));
		}

		switch (timeframe) {
			case 'today':
				return { startDate: asMoment ? this.todayStart : this.todayStart['_d'], endDate: asMoment ? this.todayEnd : this.todayEnd['_d'] };
			case 'thisWeek':
				return { startDate: asMoment ? this.weekStart : this.weekStart['_d'], endDate: asMoment ? this.weekEnd : this.weekEnd['_d'] };
			case 'lastWeek':
				return { startDate: asMoment ? this.lastWeekStart : this.lastWeekStart['_d'], endDate: asMoment ? this.weekEnd : this.weekEnd['_d'] };
			case 'month':
				return { startDate: yearStartDate, endDate: yearEndDate };
			case 'thisMonth':
				return { startDate: asMoment ? this.monthStart : this.monthStart['_d'], endDate: asMoment ? this.weekEnd : this.weekEnd['_d'] };
			case 'lastMonth':
				return { startDate: asMoment ? this.lastMonthStart : this.lastMonthStart['_d'], endDate: asMoment ? this.lastMonthEnd : this.lastMonthEnd['_d'] };
			case 'qtr1':
				return { startDate: asMoment ? this.qtr1Start : this.qtr1Start['_d'], endDate: asMoment ? this.qtr1End : this.qtr1End['_d'] };
			case 'qtr2':
				return { startDate: asMoment ? this.qtr2Start : this.qtr2Start['_d'], endDate: asMoment ? this.qtr2End : this.qtr2End['_d'] };
			case 'qtr3':
				return { startDate: asMoment ? this.qtr3Start : this.qtr3Start['_d'], endDate: asMoment ? this.qtr3End : this.qtr3End['_d'] };
			case 'qtr4':
				return { startDate: asMoment ? this.qtr4Start : this.qtr4Start['_d'], endDate: asMoment ? this.qtr4End : this.qtr4End['_d'] };
			case 'currentYear':
				return { startDate: asMoment ? this.yearStart : this.yearStart['_d'], endDate: asMoment ? this.yearEnd : this.yearEnd['_d'] };
			case 'lastYear':
				return { startDate: asMoment ? this.lastYearStart : this.lastYearStart['_d'], endDate: asMoment ? this.lastYearEnd : this.lastYearEnd['_d'] };
			case 'summer':
				return { startDate: asMoment ? this.summerStart : this.summerStart['_d'], endDate: asMoment ? this.summerEnd : this.summerEnd['_d'] };
			case 'ytd':
				return { startDate: yearStartDate, endDate: asMoment ? ytdEndDate : ytdEndDate['_d'] };
			case 'year':
				return { startDate: yearStartDate, endDate: yearEndDate };
			case 'all':
				return { startDate: yearStartDate, endDate: yearEndDate };
		}
	}

	updateYear(year: string) {
		this.refinedYear = year;
		this.qtr1Start = moment(this.refinedYear, 'YYYY').quarter(1).startOf('Q');
		this.qtr1End = moment(this.refinedYear, 'YYYY').quarter(1).endOf('Q');
		this.qtr2Start = moment(this.refinedYear, 'YYYY').quarter(2).startOf('Q');
		this.qtr2End = moment(this.refinedYear, 'YYYY').quarter(2).endOf('Q');
		this.qtr3Start = moment(this.refinedYear, 'YYYY').quarter(3).startOf('Q');
		this.qtr3End = moment(this.refinedYear, 'YYYY').quarter(3).endOf('Q');
		this.qtr4Start = moment(this.refinedYear, 'YYYY').quarter(4).startOf('Q');
		this.qtr4End = moment(this.refinedYear, 'YYYY').quarter(4).endOf('Q');
		this.summerStart = moment(this.refinedYear + '-06-01');
		this.summerEnd = moment(this.refinedYear + '-08-31');
	}
}
