import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
	MatButtonModule,
	MatIconModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatInputModule,
	MatSelectModule,
	MatOptionModule,
	MatMenuModule,
	MatCardModule,
	MatDatepickerModule,
	MatChipsModule,
	MatListModule,
	MatDialogModule,
	MatGridListModule,
	MatExpansionModule,
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatStepperModule,
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AuthGuard } from './main/auth/services/auth.guard';
import { environment } from 'environments/environment';

import { AgmCoreModule } from '@agm/core';
import { ImagesProvider } from './main/auth/pages/profile/images';
import { ImageUploadDialogComponent } from './main/auth/pages/profile/imageUploadDialog/imageUploadDialog.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { CrewSmsComponent } from './main/pages/crew/crew-sms/crew-sms.component';
import { SendMessageDialog } from './main/pages/jobs/job-edit/send-message';
import { ComponentsModule } from './main/_components/components.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioModule } from '@angular/material';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { LogviewComponent } from './main/pages/logview/logview.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const appRoutes: Routes = [
	{ path: 'auth', loadChildren: () => import('./main/auth/auth.module').then((m) => m.AuthModule) },
	{
		path: 'pages',
		loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule),
		canActivate: [],
		canActivateChild: [],
	},
	{ path: '', redirectTo: '/pages/reports', pathMatch: 'full' },
];

@NgModule({
	declarations: [AppComponent, ImageUploadDialogComponent, SendMessageDialog, LogviewComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(appRoutes),

		TranslateModule.forRoot(),

		// Material moment date module
		MatMomentDateModule,

		// Components
		ComponentsModule,

		// Material
		MatButtonModule,
		MatIconModule,

		// Fuse modules
		FuseModule.forRoot(fuseConfig),
		FuseProgressBarModule,
		FuseSharedModule,
		FuseSidebarModule,
		FuseThemeOptionsModule,

		// App modules
		LayoutModule,
		MatRadioModule,
		MatDialogModule,
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		MatOptionModule,
		MatMenuModule,
		MatCardModule,
		MatDatepickerModule,
		MatListModule,
		MatChipsModule,
		MatGridListModule,
		DragDropModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAuthModule,
		NgxPermissionsModule.forRoot(),
		Ng2TelInputModule,
		NgxSpinnerModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyAR7CxjOMoW1pS2FzyKqTZ8RmZ7L1TR9W4',
			libraries: ['places'],
		}),
		ToastrModule.forRoot({
			timeOut: 5000,
			preventDuplicates: true,
			positionClass: 'toast-top-center',
		}),
		MatExpansionModule,
		MatStepperModule,
		NgMultiSelectDropDownModule.forRoot(),
	],
	bootstrap: [AppComponent],

	entryComponents: [ImageUploadDialogComponent, SendMessageDialog, LogviewComponent],
	providers: [
		AuthGuard,
		ImagesProvider,
		AngularFireStorage,
		{ provide: LOCALE_ID, useValue: 'en-US' },
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		{ provide: MatDialogRef, useValue: {} },
	],
})
export class AppModule {}
