import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';

import {
	MatButtonModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatIconModule,
	MatInputModule,
	MatSelectModule,
	MatOptionModule,
	MatMenuModule,
	MatCardModule,
	MatDatepickerModule,
	MatChipsModule,
	MatListModule,
	MatSnackBarModule,
	MatDialogModule,
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { ConfirmDialog } from './confirm-modal';

@NgModule({
	declarations: [ConfirmDialog],
	imports: [
		FuseSharedModule,
		MatButtonModule,
		MatCheckboxModule,
		MatSnackBarModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		MatOptionModule,
		MatMenuModule,
		MatCardModule,
		MatDatepickerModule,
		MatListModule,
		MatChipsModule,
		ColorPickerModule,
		MatDialogModule,
	],
	entryComponents: [ConfirmDialog],
})
export class ComponentsModule {}
