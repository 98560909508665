import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { navigation } from 'app/navigation/navigation';
import { AuthService } from './auth.service';
import { take, map } from 'rxjs/operators';

@Injectable()
export class RouteGuardService {
	constructor(private permissionsService: NgxPermissionsService, private authService: AuthService) {}
	permissions = sessionStorage.getItem('permissions');
	loggedInUserPermissions: string[];

	checkNavigationAccess(navigationList) {
		let showPayrollInfo = false;
		if (this.permissions) {
			this.loggedInUserPermissions = this.permissions.split(',');
			showPayrollInfo = this.loggedInUserPermissions.includes('payroll') ? true : false;
		}

		navigationList = navigationList.filter((navItem) => {
			if (navItem.id === 'payroll' || navItem.id === 'crew') {
				if (showPayrollInfo) {
					return navItem;
				}
			} else {
				return navItem;
			}
		});
		return navigationList;
	}

	canNavigateTo(location: string) {
		// console.log("RouteGuardService -> canNavigateTo -> location", location)
		// const current = location.split("/")[0];
		// const perms = this.permissions.split(',');
		// // get permissions for logged in user
		// const route = navigation.find(item => item.id === current)
		// console.log("RouteGuardService -> canNavigateTo -> route", route)
		// console.log("RouteGuardService -> canNavigateTo -> currentPermissions", perms)
		// if (route.permissions) {
		//     const listHasPilots = route.permissions.some(operative => perms[operative]);
		//     console.log("RouteGuardService -> canNavigateTo -> listHasPilots", listHasPilots)
		//     return listHasPilots;
		// }
		// return false;
		// if(this.permissions){

		// }
		return this.authService.permissionsLoaded.pipe(
			map((permissionsLoaded) => {
				if (permissionsLoaded) {
					this.loggedInUserPermissions = this.authService.permissions;
					if (location === 'error') {
						return true;
					}
					const activate = this.loggedInUserPermissions.includes(location);
					return true;
				}
			})
		);
	}
}
