import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { firestore } from 'firebase';
import { JobsService } from '../jobs/services/jobs.service';

@Component({
	selector: 'app-logview',
	templateUrl: './logview.component.html',
	styleUrls: ['./logview.component.scss'],
})
export class LogviewComponent implements OnInit {
	public logList: any[] = [];
	public dates: Set<any>;
	public jobDates: any;
	public shiftByDate: any = new Map();
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<LogviewComponent>, private jobService: JobsService) {}
	ngOnChanges() {
		this.getLogDetails();
	}
	ngOnInit() {
		this.getLogDetails();
	}
	getLogDetails() {
		console.log(this.data);
		this.jobService.getLogActivity(this.data).subscribe((res) => {
			console.log(res);
			if (res && res.length > 0) {
				this.logList = res;
				this.logList.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));
				// res.forEach((data) => {
				// 	if (data.jobId == this.data.jobId && data.plandayId == this.data.id) this.logList.push(data);
				// });
				// if (this.logList) {
				// 	this.findDates();
				// 	this.findShiftsBasedOnDates();
				// }
			}
		});
	}
	findShiftsBasedOnDates() {
		let info: any[] = [];
		this.logList.forEach((log) => {
			if (info[log.jobDate]) {
				info[log.jobDate].push(log.shift);
			} else {
				info[log.jobDate] = new Array<any>();
				info[log.jobDate].push(log.shift);
			}
		});
		for (const [key, value] of Object.entries(info)) {
			let set = new Set(value);
			this.shiftByDate[key] = Array.from(set);
		}
	}

	findDates() {
		let dates = this.logList.map((date) => {
			return date.jobDate;
		});
		let date = new Set(dates);
		this.jobDates = Array.from(date);
	}
	close() {
		this.dialogRef.close();
	}
}
