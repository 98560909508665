import { Component, Input, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
	selector: 'confirm-dialog',
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
	template: `<h2 mat-dialog-title>{{ title }}</h2>
		<mat-dialog-content class="mat-typography">
			<h3>{{ message }}</h3>
		</mat-dialog-content>
		<mat-dialog-actions align="end">
			<button mat-button mat-dialog-close>Cancel</button>
			<button mat-button class="accent-bg accent" [mat-dialog-close]="true">Confirm</button>
		</mat-dialog-actions>`,
})
export class ConfirmDialog {
	constructor() {}

	@Input() title: string = '';
	@Input() message: string = '';
}
